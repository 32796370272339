// from https://docs.sibr.dev/docs/apis/docs/phases.md

export enum PHASES {
  REST,
  PRESEASON,
  EARLSEASON,
  EARLY_SIESTA,
  MIDSEASON,
  LATE_SIESTA,
  LATESEASON,
  SEASON_END,
  PRE_POSTSEASON,
  EARLY_POSTSEASON,
  EARLY_POSTSEASON_END,
  POSTSEASON,
  POSTSEASON_END,
  ELECTION,
}
