<ion-card class="green-card ion-no-padding ion-no-margin" [id]="prefix + '-' + game.id">
  <ion-card-content class="ion-no-padding">
    <ion-grid>
      <ion-row>
        <ion-col size="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
          <div [id]="prefix + '-main-' + game.id" style="position: relative">
            <ion-fab vertical="bottom" horizontal="end" slot="fixed" *ngIf="game.inProgress && allowOpenGame">
              <ion-button (click)="watchGame(game && game.id, allowOpenGame, $event)" color="dark">
                <ion-icon name="baseball-outline"></ion-icon>
                <ion-icon name="list-outline"></ion-icon>
              </ion-button>
            </ion-fab>

            <ion-grid *ngIf="isBossFight" class="boss-fight">
              <ion-row>
                <ion-col size="2">
                  <app-team team="home" [teamColor]="game.homeTeamColor" [emoji]="game.homeTeamEmoji" [hideName]="true"></app-team>
                </ion-col>
                <ion-col>
                  <ion-label>{{game.homeTeamName}}</ion-label><br>
                  <div class="score-container">
                    <ion-progress-bar [value]="game['homeHitPercent']"></ion-progress-bar>
                    <div class="score">{{game['homeHp'] || 0}} / {{game['homeMaxHp'] || 1}}</div>
                  </div>
                </ion-col>
              </ion-row>
              <ion-row>
                <ion-col size="2">
                  <app-team team="away" [teamColor]="game.awayTeamColor" [emoji]="game.awayTeamEmoji" [hideName]="true"></app-team>
                </ion-col>
                <ion-col>
                  <ion-label>{{game.awayTeamName}}</ion-label><br>
                  <div class="score-container">
                    <ion-progress-bar [value]="game['awayHitPercent']"></ion-progress-bar>
                    <div class="score">{{game['awayHp'] || 0}} / {{game['awayMaxHp'] || 1}}</div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
            <div class="team-header" *ngIf="!isBossFight">
              <ion-grid>
                <ion-row>
                  <ion-col class="ion-justify-content-end ion-no-padding ion-no-margin">
                    <app-team style="float: right" team="away" [teamColor]="game.awayTeamColor" [emoji]="game.awayTeamEmoji" [name]="game.awayTeamNickname" (click)="openTeam(game.awayTeam, $event)"></app-team>
                  </ion-col>
                  <ion-col size="1" class="ion-align-items-end ion-no-padding ion-no-margin" style="margin-top: 0.4em">
                    at
                  </ion-col>
                  <ion-col class="ion-justify-content-start ion-no-padding ion-no-margin">
                    <app-team style="float: left" team="home" [teamColor]="game.homeTeamColor" [emoji]="game.homeTeamEmoji" [name]="game.homeTeamNickname" (click)="openTeam(game.homeTeam, $event)"></app-team>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </div>

            <!-- viewbox="0 0 650 500" -->
            <svg *ngIf="game.inProgress" xmlns="http://www.w3.org/2000/svg" version="1.0" preserveAspectRatio="xMinYMin meet" viewBox="10 120 635 400">
              <filter id="dropShadow">
                <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
                <feOffset dx="0" dy="0" />
                <feComponentTransfer>
                  <feFuncA type="linear" slope="0.4"/>
                </feComponentTransfer>
                <feMerge>
                    <feMergeNode />
                    <feMergeNode in="SourceGraphic" />
                </feMerge>
              </filter>
              <g class="strokeme">
                <!-- background -->
                <path style="fill:#00a831;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="M 150,520 L 0,520 L 0,0 L 650,0 L 650,520 L 508,520 L 150,520 z "/>
                <!-- dirt -->
                <path style="opacity:1;fill:#a48e28;fill-opacity:1;stroke:#85881b;stroke-width:1.04379344;stroke-linecap:butt;stroke-linejoin:bevel;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M 205,752.36218 A 95,95 0 0 1 395,752.36218" transform="matrix(1.989102,0,0,1.978205,-268.2308,-1169.879)"/>
                <!-- dirt corner (right) -->
                <path style="fill:#00a831;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="M 328.5,439.48263 L 538.5,229.48266 L 538.5,439.48263 L 328.5,439.48263 z "/>
                <!-- dirt corner (left) -->
                <path style="fill:#00a831;fill-opacity:1;fill-rule:evenodd;stroke:none;stroke-width:1px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1" d="M 328.5,439.48263 L 124.5,235.48266 L 123.5,439.48263 L 328.5,439.48263 z "/>
                <!-- diamond grass -->
                <rect style="opacity:1;fill:#00a837;fill-opacity:1;stroke:#85881b;stroke-width:4.99999857;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" width="168.31494" height="168.31494" x="-77.781464" y="374.03622" transform="matrix(0.707107,-0.707107,0.707107,0.707107,0,0)"/>
                <!-- dirt (home) -->
                <path style="opacity:1;fill:#85881b;fill-opacity:1;stroke:none;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M 371 430 A 43 43 0 1 1  285,430 A 43 43 0 1 1  371 430 z"/>
                <!-- pitcher's mound -->
                <path style="opacity:1;fill:#b56700;fill-opacity:1;stroke:#85881b;stroke-width:2;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M 318 752.36218 A 18 18 0 1 1  282,752.36218 A 18 18 0 1 1  318 752.36218 z" transform="translate(28.5,-432.8794)"/>
                <!-- pitcher's plate -->
                <rect style="opacity:1;fill:white;fill-opacity:1;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" width="14" height="4" x="321.5" y="317.4827"/>
                <!-- foul line (right) -->
                <path style="fill:none;fill-opacity:0.75;fill-rule:evenodd;stroke:white;stroke-width:3.01047993;stroke-linecap:square;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M 363.00993,405 C 650.37706,117.62236 650.37706,117.62236 650.37706,117.62236"/>
                <!-- foul line (left) -->
                <path style="fill:none;fill-opacity:0.75;fill-rule:evenodd;stroke:white;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M 293.77042,404.99993 C -2.1085256,109.12113 -2.1085256,109.12113 -2.1085256,109.12113"/>
                <!-- batter's box (right) -->
                <rect style="opacity:1;fill:#85881b;fill-opacity:1;stroke:white;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" width="15" height="35" x="340" y="410"/>
                <!-- batter's box (left) -->
                <rect style="opacity:1;fill:#85881b;fill-opacity:1;stroke:white;stroke-width:1;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" width="15" height="35" x="300" y="410"/>
                <!-- first base -->
                <rect style="opacity:1;fill:white;fill-opacity:1;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" width="10.242652" height="10.242652" x="531.56042" y="-95.300102" transform="matrix(0.707107,0.707107,-0.707107,0.707107,0,0)"/>
                <!-- second base -->
                <rect style="opacity:1;fill:white;fill-opacity:1;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" width="10.242655" height="10.242655" x="369.26904" y="-95.643242" transform="matrix(0.707107,0.707107,-0.707107,0.707107,0,0)"/>
                <!-- third base -->
                <rect style="opacity:1;fill:white;fill-opacity:1;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" width="10.242649" height="10.242649" x="369.26904" y="66.99128" transform="matrix(0.707107,0.707107,-0.707107,0.707107,0,0)"/>
                <!-- fourth base?!? -->
                <g *ngIf="game.fifthBaseActive">
                  <image x="65" y="178" width="50" height="50" xlink:href="assets/vortex.gif" />
                  <path d="M 86,197 L 97,197 L 99,205 L 91.72761,212 L 84,205 L 86,197 z" style="fill:white;fill-opacity:1;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1" />
                  <!--
                  <line x1="57.4" y1="459" x2="215"   y2="315" stroke="white" stroke-dasharray="5 10" stroke-width="2" />
                  <line x1="57.4" y1="459" x2="327.4" y2="429" stroke="white" stroke-dasharray="5 10" stroke-width="2" />
                  -->
                  <text class="player-4"
                        filter="url(#dropShadow)"
                        [attr.x]="40"
                        [attr.y]="145"
                        text-anchor="end"
                        [attr.font-family]="font.family"
                        [attr.font-weight]="font.weight"
                        [attr.font-size]="font.size">
                    <tspan
                          fill="white"
                          stroke="black"
                          [attr.stroke-width]="font.strokeWidth"
                          text-anchor="start">
                      {{getName(getBaseRunner(3), 0)}}
                    </tspan>
                    <tspan
                          fill="white"
                          stroke="black"
                          [attr.stroke-width]="font.strokeWidth"
                          text-anchor="start"
                          [attr.x]="40"
                          [attr.y]="171">
                      {{getName(getBaseRunner(3), 1)}}
                    </tspan>
                  </text>
                </g>

                <!-- home plate -->
                <path style="fill:white;fill-opacity:1;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-opacity:1" d="M 320,423 L 335,423 L 335,430.7492 L 327.72761,438 L 320,429.78055 L 320,423 z" />
                <!-- home border -->
                <path style="opacity:1;fill:white;fill-opacity:1;stroke:none;stroke-width:3;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" d="M 295,405 C 295.15103,402.74278 293.1875,403.97917 292.28125,403.46875 C 280.62645,419.16193 280.35712,439.683 291.625,455.65625 C 305.77503,475.71519 333.59731,480.52502 353.65625,466.375 C 373.71519,452.22497 378.52502,424.40269 364.375,404.34375 L 361,405 C 362.65625,407.375 361,405 362.65625,407.375 L 293.875,406.53125 L 295,405 z M 293.875,406.53125 L 362.65625,407.375 C 374.80016,425.99135 370.24983,450.98832 351.9375,463.90625 C 333.20494,477.12061 307.30812,472.67006 294.09375,453.9375 C 283.82751,439.38417 283.83997,421.05606 293.875,406.53125 z" />
                <text class="pitcher"
                      filter="url(#dropShadow)"
                      [attr.x]="coordinates.pitcher[0]"
                      [attr.y]="coordinates.pitcher[1]"
                      text-anchor="middle"
                      [attr.font-family]="font.family"
                      [attr.font-weight]="font.weight"
                      [attr.font-size]="font.size"
                      fill="white"
                      stroke="black"
                      [attr.stroke-width]="font.strokeWidth">
                  {{getName(getPitcher())}}
                </text>
                <text class="player-home"
                      filter="url(#dropShadow)"
                      [attr.x]="coordinates.home[0]"
                      [attr.y]="coordinates.home[1]"
                      text-anchor="middle"
                      [attr.font-family]="font.family"
                      [attr.font-weight]="font.weight"
                      [attr.font-size]="font.size"
                      fill="white"
                      stroke="black"
                      [attr.stroke-width]="font.strokeWidth">
                  {{getName(getBatter())}}
                </text>
                <text class="player-1"
                      filter="url(#dropShadow)"
                      [attr.x]="coordinates.first[0]"
                      [attr.y]="coordinates.first[1]"
                      text-anchor="start"
                      [attr.font-family]="font.family"
                      [attr.font-weight]="font.weight"
                      [attr.font-size]="font.size">
                  <tspan
                        fill="white"
                        stroke="black"
                        [attr.stroke-width]="font.strokeWidth">
                    {{getName(getBaseRunner(0), 0)}}
                  </tspan>
                  <tspan
                        fill="white"
                        stroke="black"
                        [attr.stroke-width]="font.strokeWidth"
                        [attr.x]="coordinates.first[0]"
                        [attr.y]="coordinates.first[1] + 26">
                    {{getName(getBaseRunner(0), 1)}}
                  </tspan>
                </text>
                <text class="player-2"
                      filter="url(#dropShadow)"
                      [attr.x]="coordinates.second[0]"
                      [attr.y]="coordinates.second[1]"
                      text-anchor="middle"
                      [attr.font-family]="font.family"
                      [attr.font-weight]="font.weight"
                      [attr.font-size]="font.size"
                      fill="white"
                      stroke="black"
                      [attr.stroke-width]="font.strokeWidth">
                  {{getName(getBaseRunner(1))}}
                </text>
                <text class="player-3"
                      filter="url(#dropShadow)"
                      [attr.x]="coordinates.third[0]"
                      [attr.y]="coordinates.third[1]"
                      text-anchor="end"
                      [attr.font-family]="font.family"
                      [attr.font-weight]="font.weight"
                      [attr.font-size]="font.size">
                  <tspan
                        fill="white"
                        stroke="black"
                        [attr.stroke-width]="font.strokeWidth"
                        text-anchor="end">
                    {{getName(getBaseRunner(2), 0)}}
                  </tspan>
                  <tspan
                        fill="white"
                        stroke="black"
                        [attr.stroke-width]="font.strokeWidth"
                        text-anchor="end"
                        [attr.x]="coordinates.third[0]"
                        [attr.y]="coordinates.third[1] + 26">
                    {{getName(getBaseRunner(2), 1)}}
                  </tspan>
                </text>
              </g>
            </svg>
            <div *ngIf="!game.inProgress">
              <div class="game-over">
                Game Over.
              </div>
              <div class="game-summary" *ngIf="game.winner && game.loser && (game.winningScore > 0 || game.losingScore > 0)">
                <p>The {{game.winner}} beat the {{game.loser}} {{game.winningScore}} to {{game.losingScore}}.</p>
                <p *ngIf="game.shame">The {{game.loser}} have been SHAMED.</p>
              </div>
            </div>
          </div>
        </ion-col>
        <ion-col size="12" size-sm="6" size-md="6" size-lg="6" size-xl="6">
          <ion-grid class="series-record">
            <ion-row class="ion-align-items-end">
              <ion-col size="6">
                <span *ngIf="!game.isPostseason">
                  Game {{game.seriesIndex}} / {{game.seriesLength}}
                </span>
                <span *ngIf="game.isPostseason">
                  Game {{game.seriesIndex}}<br>{{game.seriesLength}} Wins Needed
                </span>
              </ion-col>
              <ion-col size="6" class="ion-text-end">
                {{weatherTypes[game.weather]}}
              </ion-col>
              <!-- TGB is not doing this anymore -->
              <!--
              <ion-col *ngIf="game && record" size="8" class="ion-text-end">
                Series record: {{game.awayTeamNickname}} {{record[1]}} / {{game.homeTeamNickname}} {{record[0]}}
              </ion-col>
              -->
            </ion-row>
          </ion-grid>
          <div class="series"></div>
          <app-score-box [hideLog]="hideLog" [game]="game" [teams]="teams" [openTeam]="openTeam"></app-score-box>
        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-card-content>
</ion-card>
