<div [class]="'bl-team ' + (hideName ? 'bl-hide-name' : 'bl-show-name')" [style]="getTeamStyle()">
  <span *ngIf="hideName">
    <div *ngIf="team !== 'home'" class="bl-team-emoji away" [style]="getEmojiStyle()" [innerHTML]="emoji"></div>
    <div *ngIf="team === 'home'" class="bl-team-emoji home" [style]="getEmojiStyle()" [innerHTML]="emoji"></div>
  </span>
  <span *ngIf="!hideName">
    <span *ngIf="team !== 'home'" class="bl-team-emoji away" [style]="getEmojiStyle()" [innerHTML]="emoji"></span>
    <span *ngIf="!hideName" class="bl-team-name" [style]="getNameStyle()">{{name}}</span>
    <span *ngIf="team === 'home'" class="bl-team-emoji home" [style]="getEmojiStyle()" [innerHTML]="emoji"></span>
  </span>
</div>