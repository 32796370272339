<div>

  <svg
    xmlns="http://www.w3.org/2000/svg"
    version="1.0"
    preserveAspectRatio="xMinYMin meet"
    viewBox="0 0 640 350">
    <!-- white border -->
    <rect style="opacity:1;fill:black;fill-opacity:1;stroke:white;stroke-width:16;stroke-linecap:butt;stroke-linejoin:miter;stroke-miterlimit:4;stroke-dasharray:none;stroke-opacity:1" x="0" y="0" width="640" height="350" />

    <g *ngIf="game.gameComplete">
      <text x="200" y="52" text-anchor="end" fill="white" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">
        FINAL ({{game.inning}})
      </text>
    </g>
    <g *ngIf="!game.gameComplete">
      <!-- inning label -->
      <text x="110" y="52" text-anchor="end" fill="white" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">
        INNING
      </text>
      <!-- inning box -->
      <rect style="opacity:1;stroke:yellow;stroke-width:4" x="120" y="20" width="80" height="50"></rect>
      <!-- inning caret -->
      <text x="132" y="52" fill="white" style="font-size: 20px" *ngIf="game.topOfInning">&#9650;</text>
      <text x="132" y="52" fill="white" style="font-size: 20px" *ngIf="!game.topOfInning">&#9660;</text>
      <!-- inning text -->
      <text x="185" y="52" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">{{game.inning}}</text>
    </g>

    <!-- out label -->
    <text x="320" y="52" text-anchor="end" fill="white" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">
      OUT
    </text>
    <!-- out box -->
    <rect style="opacity:1;stroke:yellow;stroke-width:4" x="330" y="20" width="80" height="50"></rect>
    <!-- out text -->
    <text x="395" y="52" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">{{game.halfInningOuts}}</text>

    <!-- away label -->
    <text *ngIf="!game.topOfInning" x="530" y="52" text-anchor="end" style="font-size: 25px">⚾</text>
    <image *ngIf="game.topOfInning" x="505" y="30" width="25" height="25" xlink:href="assets/Baseball_bat.svg" />
    <text x="495" y="52" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">
      {{teams && game && teams[game.awayTeam] ? teams[game.awayTeam].shorthand : 'AWAY'}}
    </text>
    <!-- away box -->
    <rect style="opacity:1;stroke:yellow;stroke-width:4" x="540" y="20" width="80" height="50"></rect>
    <!-- away text -->
    <text x="605" y="52" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">{{game.awayScore}}</text>

    <!-- ball label -->
    <text x="110" y="122" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">
      BALL
    </text>
    <!-- ball box -->
    <rect style="opacity:1;stroke:yellow;stroke-width:4" x="120" y="90" width="80" height="50"></rect>
    <!-- ball text -->
    <text x="185" y="122" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">{{game.atBatBalls}}</text>

    <!-- strike label -->
    <text x="320" y="122" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">
      STRIKE
    </text>
    <!-- strike box -->
    <rect style="opacity:1;stroke:yellow;stroke-width:4" x="330" y="90" width="80" height="50"></rect>
    <!-- strike text -->
    <text x="395" y="122" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">{{game.atBatStrikes}}</text>

    <!-- home label -->
    <text *ngIf="game.topOfInning" x="530" y="122" text-anchor="end" style="font-size: 25px">⚾</text>
    <image *ngIf="!game.topOfInning" x="505" y="100" width="25" height="25" xlink:href="assets/Baseball_bat.svg" />
    <text x="495" y="122" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">
      {{teams && game && teams[game.homeTeam] ? teams[game.homeTeam].shorthand : 'HOME'}}
    </text>
    <!-- home box -->
    <rect style="opacity:1;stroke:yellow;stroke-width:4" x="540" y="90" width="80" height="50"></rect>
    <!-- away text -->
    <text x="605" y="122" fill="white" text-anchor="end" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">{{game.homeScore}}</text>

    <!-- log box -->
    <rect style="opacity:1;stroke:yellow;stroke-width:4" x="20" y="160" width="600" height="168"></rect>
    <!-- log text -->
    <foreignObject x="30" y="165" width="580" height="153">
      <div xmlns="http://www.w3.org/1999/xhtml" style="font-size: 25px; font-family: scoreboard, sans-serif; font-weight: bold">
        {{game.updateText}}
      </div>
    </foreignObject>
  </svg>
</div>