<ion-header color="primary">
  <ion-toolbar color="primary" lines="none">
    <ion-buttons slot="primary">
      <ion-button (click)="close()">
        <ion-icon name="close-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title *ngIf="game">{{game.awayTeamNickname}} at {{game.homeTeamNickname}}</ion-title>
    <ion-buttons slot="secondary">
      <ion-button (click)="settings.toggleSpeech()">
        <ion-icon *ngIf="settings.speech()" name="volume-high-outline"></ion-icon>
        <ion-icon *ngIf="!settings.speech()" name="volume-mute-outline"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>
<app-diamond *ngIf="game" prefix="game-detail" [allowOpenGame]="false" [hideLog]="true" [game]="game"></app-diamond>
<ion-content *ngIf="game" class="ion-no-margin ion-no-border">
  <span class="log-entry" *ngFor="let update of updateLog">
    <ion-item class="ion-no-padding ion-no-margin" lines="none">
      <ion-text class="time ion-align-self-start" slot="start" lines="none">
        {{update.time}}
      </ion-text>
      <ion-text class="ion-align-self-start" lines="none">
        {{update.entry}}
      </ion-text>
    </ion-item>
    <!--
    <ion-item class="ion-no-padding ion-no-margin">
      <ion-text class="time ion-align-self-start" slot="start">
        {{update.time}}
      </ion-text>
      <ion-label class="ion-align-self-start">
        balls strikes etc.
      </ion-label>
    </ion-item>
    <ion-item class="ion-no-padding item-text-wrap">
      <ion-text>
        {{update.entry}}
      </ion-text>
    </ion-item>
    -->
  </span>
</ion-content>
